import React from "react"
import { Container, Row, Col } from "reactstrap"

import PageHeader from "../components/pageHeader"
import Service from "../components/service"

import infrastructure from "../images/icons/icon-infrastructure.png"
import construction from "../images/icons/icon-construction.png"
import installation from "../images/icons/icon-installation.png"
import documentation from "../images/icons/icon-documentation.png"
import truck from "../images/icons/icon-truck.png"
import survey from "../images/icons/icon-survey.png"
import { Helmet } from "react-helmet";

const ServicesPage = () => {
  const card = [
    {
      title: `Infrastructure Development`,
      icon: infrastructure,
      description: `Water supply and sanitation works. 
                    Excavation and earth dams. 
                    Drainage works i.e. storm drains and foundation works. 
                    Bridge construction.`,
    },
    {
      title: `Construction and Building Works`,
      icon: construction,
      description: `Architectural and structural design of residential and commercial buildings. 
                    Building construction, renovation, and maintenance. 
                    Construction and rehabilitation of access roads, driveways, parking yards ,both in paving blocks and asphalt works. 
                    Sports and recreational facilities construction and maintenance e.g., basketball courts and playgrounds.`,
    },
    {
      title: `Installation and Equipment`,
      icon: installation,
      description: `Supply and installation of irrigation systems and equipment.
                    Installation of information and telecommunication equipment`,
    },
    {
      title: `Project Management and Documentation`,
      icon: documentation,
      description: `Project management of ongoing projects.
                    Preparation of tender and contract documents`,
    },
    {
      title: `Material Supply`,
      icon: truck,
      description: `Supply of construction materials.`,
    },
    {
      title: `Survey`,
      icon: survey,
      description: `Surveying.`,
    },
  ]
  return (
    <section id="services">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alicaj Services</title>
        <meta name="description" content="INFRASTRUCTURE DEVELOPMENT CONSTRUCTION AND BUILDING WORKS INSTALLATION AND EQUIPMENT." />
      </Helmet>
      <PageHeader title="Services" />
      <section className="services">
        <Container>
          <Row>
            {card.map(service => {
              return (
                <Col md="6" lg="4">
                  <Service services={service} />
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
    </section>
  )
}

export default ServicesPage
